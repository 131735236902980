import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import { useMappedState } from "redux-react-hook";
import AlertsListTool from "../alerts/tools/AlertsListTool";
import FakeAlarmsTool from "../alarms/tools/FakeAlarmsTool";
import DashboardAlarmsTool from "../alarms/tools/DashboardAlarmsTool";

function AlertsApp(props) {
  let { id, tag } = props.match.params;

  let { currentUserObj } = useMappedState(
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
      };
    }, [])
  );
  let currentUUID = currentUserObj?.healthUser?.uuid;
  // let isDemo = false;
  let isDemo = id != undefined && id.indexOf("demo") > -1;

  return (
    <DoctorTemplate active={"alarms"}>
      <Wrapper>
        {/* <AlertsListTool /> */}
        {/*<FakeAlarmsTool />*/}
        <DashboardAlarmsTool/>
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default AlertsApp;
