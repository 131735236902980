import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";
import moment from "moment";
import DoctorTemplate from "../templates/DoctorTemplate";
import DoctorAPI from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import PatientSingleSessionPanel from "../patients/panels/PatientSingleSessionPanel";

function UserSessionApp(props) {
  let { userId, sessionId } = props.match.params;

  return (
    <DoctorTemplate active={"patients"}>
      <Wrapper>
        <PatientSingleSessionPanel uuid={userId} sessionId={sessionId} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default UserSessionApp;
