import React, {useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';

import DateTimePicker from 'react-datetime-picker';
import TypesSelector from "../tools/TypesSelector";
import {Button} from "../../trials/panels/StudySessionsPanel";
import NiceModal from "../../modals/NiceModal";
import ExportServiceTool from "../tools/ExportServiceTool";

export default function DoctorExportPanel(props) {
    const {
        isDemo = false,
        study_tag = '',
        patients = [],
        exportTimestamp,
        exportName = 'export.zip'
    } = props;

    const [types, setTypes] = useState([]);
    const [from, setFrom] = useState(+moment(props.from == undefined ? +new Date() : +props.from).startOf('day'));
    const [to, setTo] = useState(+moment(props.to == undefined ? +new Date() : +props.to).endOf('day'));

    const [exportData, setExportData] = useState();

    const [error, setError] = useState(undefined);
    let isTooBigSpan = (from != undefined && to != undefined && +to - +from > 86400000 * 30);
    let isSpanNegative = (from != undefined && to != undefined && +to < +from);

    console.log('DoctorExportPanel: render: patients = ', patients);

    return (
        <Wrapper>

            {exportTimestamp != undefined ? null :
                <DatesPlaceholder>
                    <Half>
                        <Label>
                            From
                        </Label>
                        <DateTimePicker
                            onChange={d => {
                                setFrom(+d);
                            }}
                            value={new Date(from == undefined ? +new Date() : +from)}
                        />
                    </Half>
                    <Half>
                        <Label>
                            To
                        </Label>
                        <DateTimePicker
                            onChange={d => {
                                setTo(+d);
                            }}
                            value={new Date(to == undefined ? +new Date() : +to)}
                        />
                    </Half>
                </DatesPlaceholder>
            }

            <TypesPlaceholder>
                <TypesSelector
                    onTypesChange={arr => {
                        setTypes(arr);
                    }}
                />
            </TypesPlaceholder>

            <BottomPlaceholder>
                <Button disabled={types.length == 0} onClick={() => {
                    if (types.length == 0) {
                        return;
                    }
                    if (isDemo == true) {
                        window.alert('The archive with data will be sent to your inbox.');
                        return;
                    }
                    if (isSpanNegative) {
                        setError('Date "To" must be later than date "From"');
                        return;
                    }
                    if (isTooBigSpan) {
                        setError('Please select range less than 30 days.');
                        return;
                    }
                    setExportData({
                        types: types,
                        from: from,
                        to: to,
                        users: patients,
                        study_tag: study_tag,
                        name: exportName
                    });
                }}>
                    Export
                </Button>
            </BottomPlaceholder>

            {exportData == undefined ? null :
                <NiceModal onClose={() => {
                    setExportData(undefined);
                }}>
                    <LogsInnerPlaceholder>
                        <ExportServiceTool {...exportData} />
                    </LogsInnerPlaceholder>
                </NiceModal>
            }

            {error == undefined ? null :
                <NiceModal onClose={() => {
                    setError(undefined);
                }}>
                    <LogsInnerPlaceholder>
                        {error}
                    </LogsInnerPlaceholder>
                </NiceModal>
            }


        </Wrapper>
    );
}

const TypesPlaceholder = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const LogsInnerPlaceholder = styled.div`
  width: 560px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`

`;

const BottomPlaceholder = styled.div`
  margin-top: 20px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const DatesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Half = styled.div`
  box-sizing: border-box;
  padding: 5px;
  flex: 1;
`;