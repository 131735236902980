import axios from 'axios'
import {PATIENT_API_ENDPOINT} from "../constants/config";

const API_ENDPOINT = 'https://api.cloud.ru-tsu.org';

const ChatGPTAPI = {

    async getGeneralText(text) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/gpt/general`, {
                text: text
            })).data.result;
            return pld;
        } catch (exc) {

        }
    },

    async getTeacherComment(correctAnswer, userAnswer, extra) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/gpt/teacher-comment`, {
                correctAnswer: correctAnswer,
                userAnswer: userAnswer,
                extra: extra
            })).data.result.gpt;
            return pld;
        } catch (exc) {

        }
    },

    async getGrammarExercise(grammar, level, n, extra) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/gpt/generate-grammar-exercise`, {
                grammar: grammar,
                level: level,
                n: n,
                extra: extra,
                k: 4
            })).data.result;
            return pld;
        } catch (exc) {

        }
    },

    async getWordsExercise(words = [], level, n, extra) {
        try {
            let pld = (await axios.post(`${API_ENDPOINT}/gpt/generate-words-exercise`, {
                words: words,
                level: level,
                n: n,
                extra: extra,
                k: 4
            })).data.result;
            return pld;
        } catch (exc) {

        }
    },



}

export default ChatGPTAPI;
