import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";

import MmtTemplate from "../templates/MmtTemplate";
import ls from "local-storage";
import CommonHelper from "../../helpers/CommonHelper";

function TokAuthApp(props) {
  let { uToken, hToken } = props.match.params;

  useEffect(() => {
    ls("users_cloud_token", uToken);
    ls("health_cloud_token", hToken);
    CommonHelper.linkTo("/");
    window.location.reload();
  }, [uToken, hToken]);

  return <Wrapper>loading...</Wrapper>;
}

const Wrapper = styled.div``;

export default TokAuthApp;
