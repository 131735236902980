import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";
import moment from "moment";
import DoctorTemplate from "../templates/DoctorTemplate";
import DoctorAPI from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import DoctorBraceletsPanel from "../bracelets/panels/DoctorBraceletsPanel";
import { useMappedState } from "redux-react-hook";
import DoctorSettingsPanel from "../settings/panels/DoctorSettingsPanel";

function NewSettingsApp(props) {
  let { id, tag } = props.match.params;

  let { currentUserObj, role } = useMappedState(
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
        role: state.ui.role,
      };
    }, [])
  );
  let currentUUID = currentUserObj?.healthUser?.uuid;
  // // let isDemo = false;

  // let isDemo = (id != undefined && id.indexOf('demo') > -1);

  return (
    <DoctorTemplate active={"settings"}>
      <Wrapper>
        <InnerPlaceholder>
          <DoctorSettingsPanel
            uuid={currentUUID}
            isHCPAdmin={role === "hcp-admin"}
          />
        </InnerPlaceholder>
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const InnerPlaceholder = styled.div`
  min-height: calc(80vw - 120px);
`;

export default NewSettingsApp;
