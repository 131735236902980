import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import moment from 'moment'
import DoctorTemplate from '../templates/DoctorTemplate'
import DoctorAPI from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import EditPatientPanel from "../patients/panels/EditPatientPanel";

function PatientEditApp(props) {
    let {id} = props.match.params;

    return (
        <DoctorTemplate active={'patients'}>

            <Wrapper>

                <EditPatientPanel id={id}  />

            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default PatientEditApp
