import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import SettingsPanel from "../settings/panels/SettingsPanel";
import CardiolyseCsvTool from "../cardiolyse/tools/CardiolyseCsvTool";
import DoctorTemplate from "../templates/DoctorTemplate";
import CardiolyseWiffMeasurementTool from "../cardiolyse/tools/CardiolyseWiffMeasurementTool";

function BinaryMeasurementFileApp(props) {
    let {userUUID, measurementUUID, t} = props.match.params;


    return (
        <DoctorTemplate active={'tools'}>

            <Wrapper>
                <CardiolyseWiffMeasurementTool
                    userUUID={userUUID}
                    measurementUUID={measurementUUID}
                    t={t}
                />
            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`

`;

export default BinaryMeasurementFileApp
