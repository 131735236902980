import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import moment from 'moment'
import DoctorTemplate from '../templates/DoctorTemplate'
import DoctorAPI from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import DoctorBraceletsPanel from "../bracelets/panels/DoctorBraceletsPanel";
import {useMappedState} from "redux-react-hook";
import DoctorGatewaysPanel from "../bracelets/panels/DoctorGatewaysPanel";

function GatewaysApp(props) {
    let {id, tag} = props.match.params;

    let {currentUserObj} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj
        }
    }, []));
    let currentUUID = currentUserObj?.healthUser?.uuid;
    // let isDemo = false;
    let isDemo = (id != undefined && id.indexOf('demo') > -1);


    return (
        <DoctorTemplate active={'gateways'}>

            <Wrapper>

                <DoctorGatewaysPanel uuid={currentUUID} />

            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default GatewaysApp
