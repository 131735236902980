import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled, { ThemeProvider } from "styled-components";
import { HashRouter, Switch, Route } from "react-router-dom";
import { useDispatch, useMappedState } from "redux-react-hook";

import ActivityApp from "./ActivityApp";
import SleepApp from "./SleepApp";
import IndexApp from "./IndexApp";
import StudyIndexApp from "./StudyIndexApp";
import SettingsApp from "./SettingsApp";
import RawDevSleepApp from "./RawDevSleepApp";
import EmbedIndexApp from "./EmbedIndexApp";

import * as gwActions from "../../redux/actions/GatewaysActions";
import * as actions from "../../redux/actions/AuthActions";
import * as braceletsActions from "../../redux/actions/BraceletsActions";
import * as inmatesActions from "../../redux/actions/InmatesActions";

import { Code } from "react-content-loader";
import FullPagePreloader from "../preloader/FullPagePreloader";
import LoginApp from "./LoginApp";
import MMTCloud from "../../api/MMTCloud";
import TokAuthApp from "./TokAuthApp";
import PatientApp from "./PatientApp";
import BraceletsApp from "./BraceletsApp";
import PatientsApp from "./PatientsApp";
import PatientEditApp from "./PatientEditApp";
import UserSessionApp from "./UserSessionApp";
import MelokuraApp from "./MelokuraApp";
import DoctorAPI from "../../api/DoctorAPI";
import CardioMoodIndexApp from "./CardioMoodIndexApp";
import GroupsApp from "./GroupsApp";
import GroupApp from "./GroupApp";
import ToolsApp from "./ToolsApp";
import JoinApp from "./JoinApp";
import DevApp from "./DevApp";
import BinaryMeasurementFileApp from "./BinaryMeasurementFileApp";
import { Toaster } from "react-hot-toast";
import GatewaysApp from "./GatewaysApp";
import NewSettingsApp from "./NewSettingsApp";
import AlertsApp from "./AlertsApp";
import GroupCodesPrintApp from "./GroupCodesPrintApp";
import BpReportApp from "./BpReportApp";
import BpDebugApp from "./BpDebugApp";
import ls from "local-storage";
import PasswordRecoveryApp from "./PasswordRecoveryApp";
import RegistrationApp from "./RegistrationApp";
import AccessDeniedApp from "./AccessDeniedApp";

export const isCardioMoodMode = () => {
  // try {
  //     return (window.location.href.indexOf('cardiomood') > -1);
  // } catch (exc) {
  //     return false;
  // }
  return false;
};

const userRoute = (
  <Switch>
    <Route exact path="/tokauth/:uToken/:hToken" component={TokAuthApp} />

    <Route exact path="/dev" component={DevApp} />

    <Route exact path="/bracelets" component={BraceletsApp} />
    <Route exact path="/gateways" component={GatewaysApp} />
    <Route exact path="/alerts" component={AlertsApp} />
    <Route exact path="/alarms" component={AlertsApp} />
    <Route exact path="/patients" component={PatientsApp} />

    <Route exact path="/melokura" component={MelokuraApp} />

    <Route exact path="/bp_report/:uuid/:date" component={BpReportApp} />
    <Route exact path="/bp_debugx/:uuid/:date" component={BpDebugApp} />

    <Route exact path="/studies/:code" component={GroupApp} />
    <Route exact path="/departments/:code" component={GroupApp} />

    <Route exact path="/join/:code" component={JoinApp} />
    <Route exact path="/groups/:code/print" component={GroupCodesPrintApp} />

    <Route exact path="/user/:id" component={PatientApp} />
    <Route exact path="/user/:id/edit" component={PatientEditApp} />

    <Route exact path="/studies/:tag/user/:id" component={PatientApp} />
    <Route
      exact
      path="/studies/:tag/user/:id/edit"
      component={PatientEditApp}
    />

    <Route exact path="/departments/:tag/user/:id" component={PatientApp} />
    <Route
      exact
      path="/departments/:tag/user/:id/edit"
      component={PatientEditApp}
    />

    <Route
      exact
      path="/user/:userId/session/:sessionId"
      component={UserSessionApp}
    />
    <Route exact path="/settings" component={NewSettingsApp} />
    <Route exact path="/activity" component={ActivityApp} />
    <Route exact path="/sleep" component={SleepApp} />
    <Route exact path="/studies" component={GroupsApp} />
    <Route exact path="/sleep_dev" component={RawDevSleepApp} />
    <Route exact path="/tools" component={ToolsApp} />
    <Route
      exact
      path="/cardiolyse/:userUUID/measurements/:measurementUUID/:t"
      component={BinaryMeasurementFileApp}
    />
    <Route exact path="/accessdenied" component={AccessDeniedApp} />
    <Route component={IndexApp} />
  </Switch>
);

const cardiomoodPortalUserRoute = (
  <Switch>
    <Route exact path="/settings" component={NewSettingsApp} />
    <Route component={IndexApp} />
  </Switch>
);

const cmRoute = (
  <Switch>
    <Route exact path="/dev" component={DevApp} />
    <Route exact path="/user/:id" component={PatientApp} />
    <Route exact path="/user/:id/edit" component={PatientEditApp} />
    <Route component={CardioMoodIndexApp} />
  </Switch>
);

const studyRoute = (
  <Switch>
    <Route exact path="/dev" component={DevApp} />
    <Route exact path="/tokauth/:uToken/:hToken" component={TokAuthApp} />
    <Route exact path="/settings" component={SettingsApp} />
    <Route exact path="/studies" component={GroupsApp} />
    <Route exact path="/studies/:code" component={GroupApp} />
    <Route exact path="/activity" component={ActivityApp} />
    <Route exact path="/sleep" component={SleepApp} />
    <Route exact path="/sleep_dev" component={RawDevSleepApp} />
    <Route exact path="/tools" component={ToolsApp} />
    <Route component={StudyIndexApp} />
  </Switch>
);

const guestRoute = (
  <Switch>
    <Route exact path="/join/:code" component={JoinApp} />
    <Route exact path="/dev" component={DevApp} />
    <Route exact path="/tokauth/:uToken/:hToken" component={TokAuthApp} />
    <Route exact path="/password_reset" component={PasswordRecoveryApp} />
    <Route exact path="/registration" component={RegistrationApp} />
    <Route component={LoginApp} />
  </Switch>
);

const embedRoute = (
  <Switch>
    <Route exact path="/patient/:id" component={PatientApp} />
    <Route component={EmbedIndexApp} />
  </Switch>
);

export default function RouterApp(props) {
  const [settings, setSettings] = useState(undefined);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  // const [theme, setTheme] = useState(ls.get("theme") || "light");
  const [isToggled, setIsToggled] = useState(false);

  let { currentUserObj, loading, initialized, anyLoading, theme, layout } =
    useMappedState(
      useCallback((state) => {
        return {
          currentUserObj: state.auth.currentUserObj,
          initialized: state.auth.initialized,
          loading: state.auth.loading,
          anyLoading: state.auth.loading || state.data.loading,
          theme: state.ui.theme,
          layout: state.ui.layout,
        };
      }, [])
    );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.initializeAuthorization()).then((pld1) => {
      // console.log("i_> currentUserObj, pld1 = ", currentUserObj, pld1);
      if (pld1?.currentUserObj != undefined) {
        dispatch(
          braceletsActions.loadAllBracelets(
            pld1?.currentUserObj?.usersUser?.uuid
          )
        ).then(() => {
          console.log("bracelets loaded!");
        });
        dispatch(
          gwActions.loadAllGateways(pld1?.currentUserObj?.usersUser?.uuid)
        ).then(() => {
          console.log("gateways loaded!");
        });
        dispatch(
          inmatesActions.loadAllInmates(pld1?.currentUserObj?.usersUser?.uuid)
        ).then(() => {
          console.log("inmates loaded!");
        });
      }
      DoctorAPI.getDashboardSettings().then((s_) => {
        setSettings(s_);
        setSettingsLoaded(true);
      });
    });
    if (window.location.href.indexOf("portal.corsano.com") > -1) {
    }
  }, []);

  useInterval(() => {
    if (
      initialized == false ||
      currentUserObj == undefined ||
      anyLoading == true ||
      window.location.href.indexOf("embed") > -1
    ) {
      return;
    }
    MMTCloud.getInstance()
      .refreshTokens()
      .then((x) => {
        console.log("tokens have been refreshed");
      });
  }, [2 * 60 * 1000]);

  if (loading == true && initialized == false) {
    return <FullPagePreloader visible={true} />;
  }
  if (initialized == false) {
    return <FullPagePreloader visible={true} />;
  }

  if (window.location.href.indexOf("embed") > -1) {
    return <EmbedIndexApp />;
  }

  if (currentUserObj == undefined) {
    return (
      <HashRouter>
        <Wrapper>{guestRoute}</Wrapper>
      </HashRouter>
    );
  }
  const { healthUser, usersUser } = currentUserObj;

  // console.log("RouterApp: usersUser = ", usersUser);
  if (usersUser != undefined && isCardioMoodMode() == true) {
    return (
      <HashRouter>
        <Wrapper>{cmRoute}</Wrapper>
      </HashRouter>
    );
  }
  if (window.location.href.indexOf("portal.cardiomood") > -1) {
    if (usersUser != undefined && isCardioMoodMode() == true) {
      return (
        <HashRouter>
          <Wrapper>{cardiomoodPortalUserRoute}</Wrapper>
        </HashRouter>
      );
    }
  }

  let { first_name, last_name, customer_code } = usersUser;
  // let isStudyUser = ((customer_code != undefined) && (customer_code.indexOf('study') > -1));
  // let route = (isStudyUser == true) ? studyRoute : userRoute;
  let route = userRoute;
  // if (settingsLoaded == true && settings == undefined) {
  //     return (
  //         <AccessDeniedApp user={usersUser} />
  //     )
  // }

  return (
    <HashRouter>
      <Wrapper
        className={`${theme}-theme ${
          window.location.href.indexOf("af.") > -1 ? "" : layout
        }-layout`}
      >
        {route}
        <Toaster />
      </Wrapper>
    </HashRouter>
  );
}

const Wrapper = styled.div``;

const CardWrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
