import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";

import DoctorTemplate from "../templates/DoctorTemplate";
import GroupPanel from "../groups/panels/GroupPanel";

function GroupApp(props) {
  let { code } = props.match.params;

  return (
    <DoctorTemplate active={`groups/${code}`}>
      <Wrapper>
        <GroupPanel code={code} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div``;

export default GroupApp;
